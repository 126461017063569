<template lang="pug">
  .w(
    @click="toggle"
  )
    .app-list-expandable(
      :class="{ expanded, fit: hiddenItemsCount === 0 }"
    )
      .list-item(
        :class="{ expanded }"
        v-for="item in items"
        :key="extractValue(item)"
      ) {{ extractTitle(item) }}
    .etc(
      v-if="hiddenItemsCount > 0 && !expanded"
    ) + {{ hiddenItemsCount }}
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },
      valueKey: {
        type: String
      },
      titleKey: {
        type: String
      }
    },

    data() {
      return {
        expanded: false,
        hiddenItemsCount: 0
      }
    },

    mounted() {
      this.trim()
      window.addEventListener("resize", this.trim)
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.trim)
    },

    computed: {},

    watch: {
      items: {
        deep: true,

        handler() {
          this.trim()
        }
      }
    },

    methods: {
      trim() {
        this.$nextTick(() => {
          this.hiddenItemsCount = 0
          const list = this.$el.querySelector(".app-list-expandable")
          const { right: containerRight } = list.getBoundingClientRect()

          list.querySelectorAll(".list-item").forEach(el => {
            const { right: itemRight } = el.getBoundingClientRect()
            const value = itemRight > containerRight

            value && this.hiddenItemsCount++
          })
        })
      },

      extractTitle(item) {
        return this.titleKey ? item[this.titleKey] : item
      },

      extractValue(item) {
        return this.valueKey ? item[this.valueKey] : item
      },

      toggle() {
        this.expanded = !this.expanded
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  .w
    cursor: pointer
    position: relative

    .etc
      position: absolute
      right: 0px
      top: 0px
      text-align: center
      padding: 0px 3px
      background: bisque
      border-radius: 4px
      margin-left: 4px
      transition: background 0.5s linear

      &:hover
        background: darken(bisque, 5%)

    .app-list-expandable
      width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      cursor: pointer

      display: flex
      gap: 0.25rem
      flex-wrap: nowrap

      &:not(.expanded):not(.fit)
        background: linear-gradient(90deg, $default-black 0%, $default-black 70%, transparent 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent

      &.expanded
        flex-wrap: wrap
        text-decoration: none
        color: $default-purple
        word-break: break-all
        white-space: normal

      .list-item
        font-size: 0.75rem
        transition: all 0.5s ease

        &:not(.expanded)
          &:not(:last-child)::after
            content: ","

        &.expanded
          border-radius: 2px
          padding: 3px 6px
          white-space: pre-wrap

          $list-colors: $default-turquoise, $default-purple-light, $default-orange, $default-blue

          @for $i from 1 through length($list-colors)
            $color: nth($list-colors, $i)

            &:nth-child(#{length($list-colors)}n+#{$i})
              color: darken($color, 40%)
              background: $color
</style>
